import React, { useState, useEffect, useRef, useContext } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Main_Container } from "../Containers/Main_Container";
import { Carousel } from "react-responsive-carousel";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import {
  Card,
  CardContent,
  CardActions,
  Typography,
  Button,
  Box,
  CircularProgress,
} from "@mui/material";
import "../Styles/Feed.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Footer } from "../Components/Footer";
import CategoryFetcher from "../Components/CategoryFetcher"; // Import the CategoryFetcher component
import { PriceRangeContext } from "../context/PriceRangeContext";
import { useNavigate } from "react-router-dom";

export const Feed = () => {
  const serverUrl = process.env.REACT_APP_SERVER_URL; // ${serverUrl}
  const { range, version } = useContext(PriceRangeContext);
  const { type } = useParams();
  const [products, setProducts] = useState([]);
  const [testing_state, setTesting_state] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState(""); // Manage searchTerm state
  const [selectedCategory, setSelectedCategory] = useState(null); // Manage selected category state
  const scroll = useRef(null); // Reference to the show_results div
  const navigate = useNavigate();
  const [filters, setFilters] = useState({
    priceRange: [0, 1000],
    selectedCategories: [],
  });

  const loading_style = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
  };

  const stylee = {
    backgroundColor: "transparent",
  };
  useEffect(() => {
    console.log(`Price Range: ₹${range[0]} - ₹${range[1]}`);

    const filtered = products.filter((product) => {
      const matchesPrice =
        product.pricePerSqFt >= range[0] && product.pricePerSqFt <= range[1];

      return matchesPrice;
    });

    setFilteredProducts(filtered);
  }, [range]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null); // Reset error state

      try {
        const url = type
          ? `${serverUrl}/api/data/${type}`
          : `${serverUrl}/api/data`;
        console.log(`Fetching data from: ${url}`); // Debug log
        const response = await axios.get(url);
        console.log(`Data fetched:`, response.data); // Debug log

        // Set the products fetched from the API
        setProducts(response.data);
        setFilteredProducts(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError(error);
        setProducts([]);
        setFilteredProducts([]);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [type]); // Only fetch data when 'type' changes

  // Add another useEffect to handle filtering after products or range changes
  useEffect(() => {
    if (products.length > 0) {
      const filtered = products.filter((product) => {
        const matchesPrice =
          product.pricePerSqFt >= range[0] && product.pricePerSqFt <= range[1];
        return matchesPrice;
      });

      setFilteredProducts(filtered); // Set the filtered products
      setTesting_state(filtered); // Set the testing state
    }
  }, [range, version]); // Run this effect when products or range changes

  const handleFilterChange = (appliedFilters) => {
    const { priceRange, selectedCategories } = appliedFilters;
    setFilters(appliedFilters);

    const filtered = products.filter((product) => {
      const matchesPrice =
        product.pricePerSqFt >= priceRange[0] &&
        product.pricePerSqFt <= priceRange[1];
      const matchesCategory =
        selectedCategories.length === 0 ||
        selectedCategories.includes(product.categories);
      return matchesPrice && matchesCategory;
    });

    setFilteredProducts(filtered);
  };

  const handleSearch = async (searchTerm) => {
    setSearchTerm(searchTerm); // Update searchTerm state
    if (searchTerm.trim() === "") {
      // If the search term is empty, show all products
      setFilteredProducts(products);
    } else {
      try {
        const response = await axios.get(`${serverUrl}/api/search`, {
          params: { query: searchTerm },
        });
        console.log(`Search results:`, response.data); // Debug log
        setFilteredProducts(response.data);
        setProducts(response.data);
      } catch (error) {
        console.error("Error fetching search results:", error);
        setError(error);
        setFilteredProducts([]);
      }
    }

    // Scroll to the show_results area
    if (scroll.current) {
      scroll.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleCategoryClick = (category) => {
    setSelectedCategory(category); // Set the selected category
    // Filter products based on selected category
    const filtered = products.filter(
      (product) => product.categories === category
    );
    setFilteredProducts(filtered);
  };

  const renderCategoryButtons = () => {
    if (type) {
      return (
        <CategoryFetcher
          selectedType={type}
          onCategoryClick={handleCategoryClick}
        />
      );
    }
    return null;
  };

  if (loading) {
    return (
      <div style={loading_style}>
        <CircularProgress />
      </div>
    );
  }

  if (error) {
    return <div style={loading_style}>No products found...</div>;
  }

  return (
    <div className="feed">
      <Main_Container
        onSearch={handleSearch}
        onFilterChange={handleFilterChange}
      />
      <div className="topic">
        <h2 ref={scroll}>
          {searchTerm ? `Results for "${searchTerm}"` : type}
        </h2>
      </div>
      <div className="carousel_category">
        <Carousel
          showThumbs={false}
          infiniteLoop={true}
          showStatus={false}
          showIndicators={false} // Hide navigation dots
          centerMode={false} // Align carousel items to the left
          renderArrowPrev={(onClickHandler, hasPrev, label) =>
            hasPrev && (
              <Button
                onClick={onClickHandler}
                style={{
                  position: "absolute",
                  zIndex: 2,
                  top: "calc(50% - 15px)",
                  left: 15,
                  backgroundColor: "#1976d2",
                  color: "#fff",
                  borderRadius: "50%",
                  minWidth: "40px",
                  height: "40px",
                }}
              >
                <ArrowBackIosIcon />
              </Button>
            )
          }
          renderArrowNext={(onClickHandler, hasNext, label) =>
            hasNext && (
              <Button
                onClick={onClickHandler}
                style={{
                  position: "absolute",
                  zIndex: 2,
                  top: "calc(50% - 15px)",
                  right: 15,
                  backgroundColor: "#1976d2",
                  color: "#fff",
                  borderRadius: "50%",
                  minWidth: "40px",
                  height: "40px",
                }}
              >
                <ArrowForwardIosIcon />
              </Button>
            )
          }
        >
          <div className="carousel_slide">
            {renderCategoryButtons()} {/* Category buttons rendering */}
          </div>
        </Carousel>
      </div>

      <div className="show_results">
        {filteredProducts.length > 0 ? (
          filteredProducts.map((product, index) => (
            <Card
              onClick={() => navigate(`/productDetails/${product._id}`)}
              key={index}
              className="card"
            >
              <Box>
                <Carousel showThumbs={false}>
                  {product.imageUrls.map((url, i) => (
                    <div key={i}>
                      <img
                        src={url}
                        alt={`Product Image ${i + 1}`}
                        className="card-image"
                      />
                    </div>
                  ))}
                </Carousel>
              </Box>
              <CardContent className="card_content">
                <Typography gutterBottom variant="h6">
                  {product.productTitle}
                </Typography>
                <Typography variant="h6" color="primary">
                  ₹{product.pricePerSqFt}
                </Typography>
              </CardContent>
              <CardActions className="card-actions">
                <Button size="small" className="card-button">
                  View Details
                </Button>
              </CardActions>
            </Card>
          ))
        ) : (
          <Typography variant="h6" align="center">
            No products found.
          </Typography>
        )}
      </div>
      <Footer />
    </div>
  );
};
