const data = {
  tiles: [
    "Glossy tiles",
    "Matt tiles",
    "Kitchen tiles",
    "Floor tiles",
    "Bathroom tiles",
    "Parking tiles",
    "Elevation tiles",
    "Carvings tiles",
    "Glossy elevation tiles",
    "High depth elevation tiles",
    "Picture tiles",
    "High glossy tiles",
    "Super high glossy tiles",
  ],
  granites: ["Brown Granites ", "Paradise Granites", "Gold Granites"],
  sanitarywares: [
    "Sink",
    "CP Fitings",
    "Indian toilet",
    "Western toilet (One piece)",
    "Western toilet (EWC)",
    "Table top",
    "Wash basin",
  ],
};

export default data;
